const UTM_PARAMS_STORAGE_KEY = 'utm_params';

const UTM_PARAMETERS = new Set([
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
]);

export const getSavedUtmParams = () => {
    let currentValue = null;

    try {
        currentValue = JSON.parse(localStorage.getItem(UTM_PARAMS_STORAGE_KEY));
    } catch (err) {
    }

    if (!Array.isArray(currentValue)) {
        currentValue = [];
    }

    return currentValue;
}

const initUtmParameters = () => {
    console.log('Utm params');

    const saveUtmParams = (params) => {
        localStorage.setItem(UTM_PARAMS_STORAGE_KEY, JSON.stringify(params));
    }

    const getUtmParamsFromUrl = () => {
        const utmParams = {};
        new URLSearchParams(location.search).forEach((value, key) => {
            if (!UTM_PARAMETERS.has(key)) {
                return;
            }

            utmParams[key] = value;
        });

        return utmParams;
    }

    const shouldSaveUtmParams = (oldParams, newParams) => {
        const summaryParams = new Set([...Object.keys(oldParams), ...Object.keys(newParams)]);
        for (const key of summaryParams.keys()) {
            // Если был добавлен или удалён любой параметр.
            if (oldParams[key] === undefined || newParams[key] === undefined) {
                return true;
            }

            // Если поменялось значение у любого параметра.
            if (oldParams[key] !== newParams[key]) {
                return true;
            }
        }

        return false;
    }

    const getLastUtmParams = (utmParams) => {
        const oldLastUtmParams = utmParams.length !== 0
            ? utmParams[utmParams.length - 1]
            : {}

        return oldLastUtmParams;
    }

    const urlUtmParams = getUtmParamsFromUrl()
    if (Object.keys(urlUtmParams).length !== 0) {
        const utmParams = getSavedUtmParams();
        const oldLastUtmParams = getLastUtmParams(utmParams);

        if (shouldSaveUtmParams(oldLastUtmParams, urlUtmParams)) {
            utmParams.push(urlUtmParams)
            saveUtmParams(utmParams);
        }
    }
}

initUtmParameters();
